<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="display-1 font-weight-bold mb-3">教育コンテンツ 管理画面</h1>
      </v-col>
      <v-col cols="12">
        教育コンテンツは
        <a :href="`https://${this.jc3LearningDomain}/admin/events/`" target="_blank">JC3 Learning<v-icon small>mdi-open-in-new</v-icon></a>
        で管理します。
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { jc3LearningDomain } from '@/../config.json'
export default {
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
    }
  }
}
</script>